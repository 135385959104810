import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

/*--------------------
* Brand
----------------------*/
function Brand(props) {
  return (
    <>
      <div className="p-3 text-center d-flex align-items-center justify-content-center w-100">
        <img src={props.brand_icon} title="Brand" alt="Brand" width="50px" />
      </div>
    </>
  );
}

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
  const Brand_list = [
    { id: 1, brand_icon: "img/brands/hmm-logo.png" },
    { id: 2, brand_icon: "img/brands/jms-logo.png" },
    { id: 3, brand_icon: "img/brands/jobhuntergpt-logo.png" },
    { id: 4, brand_icon: "img/brands/stocknews-logo.png" },
    { id: 5, brand_icon: "img/brands/telephant-logo.png" },
    { id: 6, brand_icon: "img/brands/yolk-logo.png" },
    { id: 7, brand_icon: "img/brands/marketingdwarf-logo.png" },
  ];

  return (
    <>
      <div className="bg-slate-900 py-8">
        <div className="container">
          <Swiper
            spaceBetween={20}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {Brand_list.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <Brand brand_icon={val.brand_icon} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}
