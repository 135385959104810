import React from "react";
/*--------------------
* Service List Section
----------------------*/
export default function About() {
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gray"
      >
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto"
                src="img/about-banner.png"
                title="Banner"
                alt="Banner"
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                We are makers.
              </h3>
              <p className="text-[16px] md:text-[18px]">
                We love what we do - getting ideas and business of the ground.
                From idea to execution, we love doing it all. We are a team of
                passionate individuals who love to create and build things that
                matter.
              </p>
              <div className="grid grid-cols-12 pt-5">
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">7+</h5>
                  <span>Projet Completed</span>
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">
                    100+
                  </h5>
                  <span>Happy Customers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
