/*--------------------
* Skill List Section
----------------------*/
export default function Philosophy() {
  return (
    <>
      <section
        data-scroll-data="2"
        id="skill"
        className="section experience-section !pb-0"
      >
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1">
            <div className="lg:col-span-6 lg:pl-9">
              <div className="section-heading">
                <h3 className="m-0">
                  <span>Our Philosophy</span>
                </h3>
              </div>
              <div className="text-[16px] md:text-[18px]">
                <p>
                  We are a team of passionate individuals who love to create and
                  build things that matter. We love what we do - getting ideas
                  and business of the ground. From idea to execution, we love
                  doing it all.
                </p>
              </div>
            </div>
            <div className="lg:col-span-6 text-center pt-[40px] md:pt-0">
              <img className="mx-[auto]" src="img/rocket.png" title="" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
